import useAuthStore from '../store/authStore'
import useProfileStore from '../store/profileStore'
import accountCircleIcon from '../assets/account-circle.svg'
import arrowIcon from '../assets/icon-chevron-down.svg'
import logoutIcon from '../assets/logout.svg'
import { useState } from 'react'

interface Props {
 className?: string
}

const AccountDropDown: React.FC<Props> = ({ className = '' }) => {
 const [isOpen, setIsOpen] = useState(false)
 const profile = useProfileStore((state) => state.profile)
 const logout = useAuthStore((state) => state.logout)

 const toggleDropdown = () => setIsOpen(!isOpen)

 return (
  <div className='account-dropdown'>
   <button
    id='account-dropdown'
    data-dropdown-toggle='account-dropdown-menu'
    type='button'
    className='
    text-black bg-white rounded-lg border border-gray-200 font-bold text-sm text-center inline-flex items-center
    px-2 py-1 max-w-[105px] md:max-w-[134px] hover:bg-gray-200
    '
    onClick={toggleDropdown}
   >
    <img className='h-auto w-5 md:w-6 mr-1' src={accountCircleIcon} alt='account' />
    <span className='truncate'>{profile?.username}</span>
    <img className='h-auto w-5 md:w-6 ml-2' src={arrowIcon} alt='arrow-down' />
   </button>

   {
    isOpen && <div id='account-dropdown-menu' className='absolute z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-30 md:w-36'>
     <ul className='text-sm text-black'>
      <li className='p-3 md:p-4 hover:bg-gray-200 hover:cursor-pointer'>
       <img className='h-auto w-5 md:w-6 mr-1 md:mr-2 inline-block' src={logoutIcon} alt='logout' />
       <a href='#' className='inline-block text-error font-bold' onClick={logout}>Log out</a>
      </li>
     </ul>
    </div>
   }
  </div>
 )
}

export default AccountDropDown
