import axios, { Axios } from "axios"
import { plainToInstance } from "class-transformer"
import { auth } from "../lib/firebase-config"
import TaxInformation from "../model/taxInformation"
import PaymentTransaction from "../model/paymentTransaction"

class PaymentClient {
    private readonly paymentAxiosInstance: Axios

    constructor() {
        this.paymentAxiosInstance = axios.create({
            baseURL: process.env.REACT_APP_PAYMENT_SERVICE_BASE_URL,
            withCredentials: false,
        })
        this.paymentAxiosInstance.interceptors.request.use(async (config) => {
            const token = await auth.currentUser?.getIdToken()
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`
                config.headers['Content-Type'] = 'application/json';
                config.headers['Accept-Language'] = 'EN'
            }
            return config
        }, (error) => {
            // Handle the error
            return Promise.reject(error)
        })
    }

    async getTaxInformation(): Promise<TaxInformation | null> {
        try {
            const resp = await this.paymentAxiosInstance.get<{ data: TaxInformation }>('/user/information', {})
            return resp.data.data
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async createTaxInformation({ fullName, email, phoneNumber, identificationType, identification, addressDetails, postalCode }:
        { fullName: string, email: string, phoneNumber: string, identificationType: string, identification: string, addressDetails: string, postalCode: string, }): Promise<boolean> {
        try {
            const resp = await this.paymentAxiosInstance.post<{ status: string }>(`/user/information`, {
                fullName,
                email,
                phoneNumber,
                identificationType,
                identification,
                addressDetails,
                postalCode,
            })
            return resp.data.status === 'success'
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateTaxInformation({ id, fullName, email, phoneNumber, identificationType, identification, addressDetails, postalCode }:
        { id: string, fullName: string, email: string, phoneNumber: string, identificationType: string, identification: string, addressDetails: string, postalCode: string, }): Promise<boolean> {
        try {
            const resp = await this.paymentAxiosInstance.patch<{ status: string }>(`/user/information/${id}`, {
                fullName,
                email,
                phoneNumber,
                identificationType,
                identification,
                addressDetails,
                postalCode,
            })
            return resp.data.status === 'success'
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async pay(productId: number, paymentType: string, email: string, customerName: string): Promise<string | null> {
        try {
            const resp = await this.paymentAxiosInstance.post<{ data: string }>('/pay', {
                productId: productId,
                paymentType: paymentType,
                email,
                customerName,
            })
            return resp.data.data
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getTransactionStatus(txId: string): Promise<PaymentTransaction | null> {
        const resp = await this.paymentAxiosInstance.get<{ data: PaymentTransaction }>(`/check/${txId}`)
        return plainToInstance(PaymentTransaction, resp.data.data)
    }

    async getDownloadQrcode(txId: string): Promise<Blob> {
        const resp = await this.paymentAxiosInstance.get<Blob>(`/download/${txId}/qrcode`, { responseType: 'blob' })
        return resp.data
    }
}

export default PaymentClient