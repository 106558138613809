import { Expose, Type } from 'class-transformer'
import { formatNumberWithComma } from '../utils/number'

export enum PaymentType {
  PromptPay = 'promptpay',
  CreditCard = 'credit_card',
  KPlus = 'mobile_banking_kbank',
  SCB = 'mobile_banking_scb',
  Krungsri = 'mobile_banking_bay',
  Krungthai = 'mobile_banking_ktb',
  MBanking = 'mobile_banking_bbl',
  TrueWallet = 'truemoney_jumpapp',
  LinePay = 'rabbit_linepay',
  InAppPurchase = 'in_app_purchase',
  NotFound = 'NOT_FOUND',
}

export enum TransactionStatus {
  pending = 'pending',
  successful = 'successful',
  failed = 'failed',
}

enum CreditCardFailedReasonKey {
  FAILED_PROCESSING = 'failed_processing',
  INSUFFICIENT_FUND = 'insufficient_fund',
  INVALID_ACCOUNT_NUMBER = 'invalid_account_number',
  STOLEN_OR_LOST_CARD = 'stolen_or_lost_card',
  FAILED_FRAUD_CHECK = 'failed_fraud_check',
  PAYMENT_REJECTED = 'payment_rejected',
  UNKNOWN = 'unknown',
}

enum FailedReasonKey {
  FAILED_PROCESSING = 'failed_processing',
  INSUFFICIENT_BALANCE = 'insufficient_balance',
  PAYMENT_CANCELLED = 'payment_cancelled',
  PAYMENT_EXPIRED = 'payment_expired',
  PAYMENT_REJECTED = 'payment_rejected',
  INVALID_ACCOUNT = 'invalid_account',
  INSUFFICIENT_FUND = 'insufficient_fund',
  TIMEOUT = 'timeout',
  UNKNOWN = 'unknown',
}

export class QRCodeImage {
  id?: string
  kind?: string
  object?: string
  deleted?: boolean
  filename?: string
  livemode?: boolean
  location?: string

  @Expose({ name: 'created_at' })
  createdAt?: string

  @Expose({ name: 'download_uri' })
  downloadUri?: string
}

export class QRCode {
  type?: string

  @Type(() => QRCodeImage)
  image?: QRCodeImage

  object?: string
}

export class Metadata {
  email?: string
  price?: string
  orderId?: string

  @Expose({ name: 'qr_code' })
  @Type(() => QRCode)
  qrCode?: QRCode

  currency?: string
  tokenName?: string
  failReason?: string | null
  paymentFee?: string
  paymentVat?: string
  tokenBonus?: number
  failureCode?: string | null
  tokenAmount?: number
  tokenSymbol?: string
  customerName?: string
  totalPriceVat?: string
  netTotalFeeOpn?: string
  revenueFromOpn?: string
  revenueExcludeVat?: string
  currentServiceFeeFlatOpn?: string
  currentServiceFeeRateOpn?: string
}

export class TransactionLog {
  id?: string
  status?: string
  channel?: string
  eventKey?: string
  metadata?: any
  createdAt?: string
  updatedAt?: string | null
}

class PaymentTransaction {
  id?: string
  paymentType?: PaymentType
  amount?: number
  currency?: string
  source?: string
  description?: string | null
  userRefId?: string
  provider?: string
  providerRefId?: string
  productId?: string
  status?: TransactionStatus

  @Type(() => Metadata)
  metadata?: Metadata

  tkxRefId?: string | null
  tkxTransactionStatus?: string
  customerInformation?: any | null
  channel?: string
  createdAt?: string
  updatedAt?: string

  @Type(() => TransactionLog)
  transactionLogs?: TransactionLog[]

  get displayAmount(): string {
    return formatNumberWithComma(this.amount ?? 0)
  }

  get getFailedReasonText(): string {
    if (this.paymentType === PaymentType.CreditCard) {
      switch (this.metadata?.failureCode) {
        case CreditCardFailedReasonKey.FAILED_PROCESSING:
          return 'the payment process has failed.'
        case CreditCardFailedReasonKey.INSUFFICIENT_FUND:
          return 'the card has reached the credit limit or insufficient funds in the account.'
        case CreditCardFailedReasonKey.INVALID_ACCOUNT_NUMBER:
          return 'the account number for the payment method is invalid.'
        case CreditCardFailedReasonKey.STOLEN_OR_LOST_CARD:
          return 'the card was marked as stolen or lost.'
        case CreditCardFailedReasonKey.FAILED_FRAUD_CHECK:
          return 'the card was marked as fraudulent.'
        case CreditCardFailedReasonKey.PAYMENT_REJECTED:
          return 'the payment was rejected by the issuer.'
        case CreditCardFailedReasonKey.UNKNOWN:
        default:
          return 'the payment process has failed with an unspecified reason.'
      }
    } else if (this.paymentType === PaymentType.PromptPay && this.status === TransactionStatus.pending) {
      return 'this QR Code has expired. Please request a new one.'
    } else {
      switch (this.metadata?.failureCode) {
        case FailedReasonKey.FAILED_PROCESSING:
          return 'general payment processing failure.'
        case FailedReasonKey.INSUFFICIENT_BALANCE:
          return 'insufficient funds in the account or the payment method has reached its limit.'
        case FailedReasonKey.PAYMENT_CANCELLED:
          return 'payment cancelled by the payer.'
        case FailedReasonKey.PAYMENT_EXPIRED:
          return 'payment expired.'
        case FailedReasonKey.PAYMENT_REJECTED:
          return 'payment rejected by the issuer.'
        case FailedReasonKey.INVALID_ACCOUNT:
          return 'valid account for the payment method not found.'
        case FailedReasonKey.INSUFFICIENT_FUND:
          return 'insufficient funds in the account or the payment method has reached its limit.'
        case FailedReasonKey.TIMEOUT:
          return 'payer did not take action before charge expiration.'
        case FailedReasonKey.UNKNOWN:
        default:
          return 'the payment process has failed with an unspecified reason.'
      }
    }
  }
}

export default PaymentTransaction