import axios, { Axios } from "axios";
import { auth } from "../lib/firebase-config";

class AuthenticationClient {
    private readonly authAxiosInstance: Axios

    constructor() {
        this.authAxiosInstance = axios.create({
            baseURL: process.env.REACT_APP_AUTHENTICATION_SERVICE_BASE_URL,
            withCredentials: false,
        })
    }

    async createCustomToken(token: string): Promise<string> {
        try {
            const resp = await this.authAxiosInstance.post<{ data: { customToken: string } }>('/v1/create-custom-token', {}, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            return resp.data.data.customToken
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async sendPasswordResetEmail(email: string): Promise<void> {
        try {
            const resp = await this.authAxiosInstance.post<{ success: boolean }>('/v1/send-password-reset-email', { email })
            if (!resp.data.success) return Promise.reject()
        } catch (error) {
            return Promise.reject(error)
        }
    }

}

export default AuthenticationClient