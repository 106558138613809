/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Loading from '../component/Loading'
import createCardFailedImg from '../assets/credit-card-failed.png'
import useAuthStore from '../store/authStore'
import usePaymentTransactionStore from '../store/paymentTransactionStore'

const PaymentFailedPage = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const txId = queryParams.get('txId')
  const navigate = useNavigate()
  const user = useAuthStore((state) => state.user)
  const getTransaction = usePaymentTransactionStore((state) => state.getPaymentTransaction)
  const transaction = usePaymentTransactionStore((state) => state.transaction)
  const txLoading = usePaymentTransactionStore((state) => state.loading)

  useEffect(() => {
    if (!user) return

    if (txId) {
      getTransaction(txId)
    }
  }, [])

  return (
    <div className="flex flex-col items-center justify-center">
      {!!user && !txLoading ? (
        <div className="flex flex-col items-center bg-white rounded-lg shadow-s mt-8 py-8" style={{ maxWidth: 592 }}>
          <div className="flex flex-col items-center px-16">
            <div className="flex justify-center mb-5">
              <img src={createCardFailedImg} alt="payment success" className="w-20 h-20" />
            </div>
            <h2 className="text-gray-800 font-bold text-lg mb-1">Top-up failed</h2>
            <p className="text-gray-500 text-center text-base">{`Sorry, ${transaction?.getFailedReasonText} Please try again.`}</p>
          </div>
          <div className="mt-6 text-base">
            <span className="text-gray-500">Order ID</span>
            <span className="ml-2 text-gray-800 font-bold">{transaction?.metadata?.orderId}</span>
          </div>
          <div className="w-full px-16">
            <button
              onClick={() => navigate('/packages')}
              className="w-full rounded py-2.5 px-3.5 mt-6 bg-nightBlack shadow-xs text-white text-base font-bold"
            >
              Go to package page
            </button>
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  )
}

export default PaymentFailedPage
