export const formatNumberForPayment = (input: string) => {
    const amount = parseInt(input)
    return (amount * 100)
}

export function formatNumberWithComma(value: number): string {
    const formattedValue = value / 100

    // If the number is a whole number, return it with commas and no decimal
    if (formattedValue % 1 === 0) {
        return formattedValue.toLocaleString() // No decimals, formatted with commas
    } else {
        return formattedValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    }
}