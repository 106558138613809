import React, { ReactElement, useImperativeHandle, useState } from "react"

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
    label: string
    id: string
    leadingIcon?: string
    leadingIconAlt?: string
    trailingIcon?: string
    trailingAction?: ReactElement
    error?: string | null
    validateField?: () => boolean
}

const TextInput = React.forwardRef<HTMLInputElement, InputProps>(
    ({
        error,
        label,
        id,
        type,
        placeholder,
        leadingIcon,
        leadingIconAlt,
        trailingIcon,
        trailingAction,
        validateField,
        ...props
    }, ref) => {
        const inputRef = React.useRef<HTMLInputElement>(null)
        const [isFocused, setIsFocused] = useState(false)
        const showError = error && !isFocused

        useImperativeHandle(ref, () => inputRef.current!)

        return <div className={`relative z-0 w-full ${props.className}`}>
            {leadingIcon && <div className="absolute left-0 pl-3 top-4 pointer-events-none">
                <img src={leadingIcon} alt="icon" ></img>
            </div>}
            <input  {...props}
                type={type}
                id={id}
                className={`block caret-primary rounded-t 
             ${leadingIcon ? 'px-12' : 'px-4'}  pt-5 w-full text-base text-gray-800 bg-white 
             border-0 border-b-2 border-gray-300 
             appearance-none focus:outline-none 
             focus:ring-0 focus:bg-primary-50 focus:border-primary peer 
             disabled:bg-gray-100 disabled:text-gray-400
             ${showError && '!bg-error-50 !border-error'}`}
                placeholder=""
                onFocus={() => setIsFocused(true)}
                onBlur={(event) => {
                    if (props.onBlur) {
                        props.onBlur(event)
                    }
                    setIsFocused(false)
                }}

            />
            <label htmlFor={id} 
                className={
                    `absolute text-base text-gray-400 duration-300 
                transform -translate-y-4 scale-75 top-4 z-10 origin-[0] 
                ${leadingIcon ? 'start-12' : 'start-4'} peer-focus:text-primary peer-placeholder-shown:scale-100 
                peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 
                rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto
                ${showError && '!text-error peer-placeholder-shown:!text-error' }`
                }>{label}</label>
           {trailingIcon && <img src={trailingIcon} className="pl-2 absolute right-2" alt={id} />}
            {trailingAction}
            {showError && <p  className="mt-1 ms-4 text-xs text-error">{error}</p>}
        </div>
    }
)

export default TextInput
