import { useEffect, useState } from 'react'
import useAuthStore from "../store/authStore"
import usePackagesStore from "../store/packageStore"
import TextInput from "../component/TextInput"
import iconDown from "../assets/icon-chevron-down.svg"
import iconRight from "../assets/icon-chevron-right.svg"
import { isValidEmail, isValidPassportId, isValidPostal, isValidThaiId, isValidThaiPostal } from '../utils/regex'
import Button from '../component/Button'
import TaxInformation from '../model/taxInformation'
import ToastCustom from '../component/ToastCustom'

const TaxInformationPages = ({
    information,
    open = false,
    onClose,
    onConfirm
}: {
    information: TaxInformation | null,
    open: boolean,
    onClose: () => void,
    onConfirm: () => void
}) => {
    const updateTaxInformation = usePackagesStore((state) => state.updateTaxInformation)
    const taxLoading = usePackagesStore((state) => state.taxLoading)
    const [name, setName] = useState('')
    const [nameErrorText, setNameErrorText] = useState<string | null>(null)
    const [email, setEmail] = useState('')
    const [emailErrorText, setEmailErrorText] = useState<string | null>(null)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [idCard, setIdCard] = useState('')
    const [idCardErrorText, setIdCardErrorText] = useState<string | null>(null)
    const [address, setAddress] = useState('')
    const [addressErrorText, setAddressErrorText] = useState<string | null>(null)
    const [postal, setPostal] = useState('')
    const [postalErrorText, setPostalErrorText] = useState<string | null>(null)
    const [nationality, setNationality] = useState('thai_national_id')
    const [nationName, setNationName] = useState('Thai')
    const [showNation, setShowNation] = useState(false)
    const user = useAuthStore((state) => state.user)

    useEffect(() => {
        if (information) {
            setName(information.fullName)
            setEmail(information.email ?? '')
            setPhoneNumber(information.phoneNumber)
            setNationality(information.identificationType)
            setNationName(information.identificationType === 'thai_national_id' ? 'Thai' : 'Foreigner')
            setIdCard(information.identification)
            setAddress(information.addressDetails)
            setPostal(information.postalCode)
        } else {
            if (user) {
                setEmail(user!.email ?? '')
                setPhoneNumber(user!.phoneNumber!)
            }
        }
    }, [user, information])

    const validateFullName = (setState: boolean = false) => {
        let err = null
        if (!name) {
            err = 'Full Name can\'t be empty'
        } else if (name.length < 2) {
            err = 'Full Name is too short'
        }
        if (setState) setNameErrorText(err)
        return err
    }
    const validateEmail = (setState: boolean = false) => {
        let err = null
        if (email && !isValidEmail(email)) {
            err = 'Please enter a valid email'
        }
        if (setState) setEmailErrorText(err)
        return err
    }

    const validateIdCard = (setState: boolean = false, value: string = nationality) => {
        let err = null
        if (value === 'thai_national_id') {
            if (!idCard) {
                err = 'Thai ID card number can\'t be empty'
            } else if (!isValidThaiId(idCard)) {
                err = 'Please enter a valid Thai ID card number'
            }
        } else {
            if (!idCard) {
                err = 'ID Passport number can\'t be empty'
            } else if (!isValidPassportId(idCard)) {
                err = 'Please enter a valid ID Passport number'
            }
        }
        if (setState) setIdCardErrorText(err)
        return err
    }

    const validateAddress = (setState: boolean = false) => {
        let err = null
        if (!address) {
            err = 'Address details can\'t be empty'
        } else if (address.length < 2) {
            err = 'Please enter a valid Address details'
        }
        if (setState) setAddressErrorText(err)
        return err
    }

    const validatePostal = (setState: boolean = false) => {
        let err = null
        let isValid = true
        if (nationality === 'thai_national_id') {
            isValid = isValidThaiPostal(postal)
        } else {
            isValid = isValidPostal(postal)
        }
        if (!postal) {
            err = 'Postal  can\'t be empty'
        } else if (!isValid) {
            err = 'Please enter a valid Postal'
        }
        if (setState) setPostalErrorText(err)
        return err
    }

    const onClickNation = (value: string) => {
        setShowNation(false)
        setNationality(value)
        setNationName(value === 'thai_national_id' ? 'Thai' : 'Foreigner')
        if (idCard.length > 0) {
            setIdCard('')
        }
        validateIdCard(true, value)
        if (postal.length > 0) {
            validatePostal(true)
        }
    }

    const isFormValid = () => {
        return !validateFullName() && !validateEmail() && !validateIdCard() && !validateAddress() && !validatePostal()
    }

    const submitTaxInformation = async () => {
        const success = await updateTaxInformation(name, email, phoneNumber, nationality, idCard, address, postal)
        if (success) {
            onConfirm()
        } else {
            ToastCustom.error('Oops, something went wrong. Please try again later.')
        }
    }

    return <>
        {open &&
            <div className="relative z-40" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                            <div className="bg-white p-5 ">
                                <div className="sm:flex sm:items-start">
                                    <div className="text-start sm:mt-0 sm:text-left">
                                        <h3 className="text-fontSize-md font-semibold text-gray-800" id="modal-title">Request tax invoice</h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">Please fill in the information as it appears on your <span className="font-semibold">citizen ID card</span> and recheck your info. If there is any mistake you can't edit info in tax invoice again.</p>
                                        </div>
                                        <h3 className="mt-5 mb-4 text-fontSize-base font-semibold text-gray-800" id="modal-title">Basic information</h3>
                                        <div className="h-[76px]">
                                            <TextInput
                                                required
                                                id='fullName'
                                                type="text"
                                                label='Full Name*'
                                                value={name}
                                                onChange={(e) => {
                                                    setName(e.target.value)
                                                }}
                                                onBlur={() => validateFullName(true)}
                                                error={nameErrorText}
                                                maxLength={50}
                                            />
                                        </div>
                                        <div className="h-[76px]">
                                            <TextInput
                                                required
                                                id='email'
                                                type="email"
                                                label='Email (optional)'
                                                value={email}
                                                leadingIcon=''
                                                onChange={(e) => {
                                                    setEmail(e.target.value)
                                                }}
                                                onBlur={() => validateEmail(true)}
                                                error={emailErrorText}
                                            />
                                        </div>
                                        <div className="h-[76px]">
                                            <TextInput
                                                required
                                                id='phone'
                                                type="text"
                                                label='Phone number'
                                                value={phoneNumber}
                                                leadingIcon=''
                                                disabled
                                            />
                                        </div>
                                        <h3 className="mt-1 mb-3 text-fontSize-base font-semibold text-gray-800" id="modal-title">Tax information</h3>
                                        <div className="relative inline-block text-left w-full h-16">
                                            <div className="relative z-0 w-full">
                                                <div className="flex flex-row w-full justify-between cursor-pointer" onClick={() => setShowNation(true)}>
                                                    <div className="flex flex-col">
                                                        <p className="px-4 text-[12px] text-gray-400">Nationality *</p>
                                                        <p className="px-4 text-base text-gray-700" >{nationName}</p>
                                                    </div>
                                                    <img src={showNation ? iconDown : iconRight} alt="edit icon" className="mr-1 mt-1" />
                                                </div>
                                                <div className="h-[1px] mt-[3px] w-full bg-gray-300"></div>
                                            </div>
                                            {showNation &&
                                                <div className="absolute z-10 mt-2 w-full origin-top-right divide-y divide-gray-200 rounded-2xl bg-white shadow-lg ring-1 ring-black/5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" >
                                                    <div className="py-1" role="none">
                                                        <button onClick={() => onClickNation('thai_national_id')} className="block w-full px-4 py-2 text-base text-gray-700 text-start" role="menuitem" id="thai_national_id">Thai</button>
                                                    </div>
                                                    <div onClick={() => onClickNation('passport')} className="py-1" role="none">
                                                        <button className="block w-full px-4 py-2 text-base text-gray-700 text-start" role="menuitem" id="passport">Foreigner</button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="h-[76px]">
                                            <TextInput
                                                required
                                                id='icCard'
                                                type="text"
                                                label={nationality === 'thai_national_id' ? 'Thai ID card number *' : 'Passport number *'}
                                                value={idCard}
                                                onChange={(e) => {
                                                    setIdCard(e.target.value)
                                                }}
                                                onBlur={() => validateIdCard(true)}
                                                error={idCardErrorText}
                                                maxLength={
                                                    nationality === 'thai_national_id' ? 13 : 99}
                                            />
                                        </div>
                                        <div className="h-[76px]">
                                            <TextInput
                                                required
                                                id='address'
                                                type="text"
                                                label='Address details *'
                                                value={address}
                                                leadingIcon=''
                                                onChange={(e) => {
                                                    setAddress(e.target.value)
                                                }}
                                                onBlur={() => validateAddress(true)}
                                                error={addressErrorText}
                                            />
                                        </div>
                                        <div className="h-[76px]">
                                            <TextInput
                                                required
                                                id='postal'
                                                type="text"
                                                label='Postal code *'
                                                value={postal}
                                                leadingIcon=''
                                                onChange={(e) => {
                                                    setPostal(e.target.value)
                                                }}
                                                onBlur={() => validatePostal(true)}
                                                error={postalErrorText}
                                                maxLength={
                                                    nationality === 'thai_national_id' ? 5 : 99}
                                            />
                                        </div>
                                        <div className="flex flex-row mt-1">
                                            <button className='flex-1 h-10 bg-white text-gray-700 text-sm rounded font-bold border border-gray-300' onClick={() => { onClose() }}>Cancel</button>
                                            <Button type="submit" loading={taxLoading} disabled={!isFormValid()} className='flex-1 ml-2 h-10 text-sm !p-0' onClick={() => submitTaxInformation()}>Confirm</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        }
    </>
}
export default TaxInformationPages