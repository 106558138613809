import { create } from "zustand";
import DirectusClient from "../services/directus";
import Customer from "../model/customer";

interface ProfileState {
  profile: Customer | null;
  loading: boolean;
  getProfile: () => Promise<void>
}

const client = new DirectusClient()

const useProfileStore = create<ProfileState>((set) => ({
  profile: null,
  loading: true,
  getProfile: async () => {
    let profile: Customer | null = null
    try {
      profile = await client.getProfile()
    } catch (error) {
      console.log(error)
    }
    set({ profile, loading: false })
  }
}));

export default useProfileStore