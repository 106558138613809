import TaxInformation from "../model/taxInformation"
import editIcon from "../assets/edit_square.svg"

const TaxInformationCard = ({ taxInformation, onEdit }: { taxInformation: TaxInformation | null, onEdit: () => void }) => {
    return (
        <>
            {!taxInformation && <button className="w-full py-3 px-4 bg-white border border-gray-200 rounded-lg flex" onClick={() => onEdit()}>
                <img src={editIcon} alt="edit icon" className="mr-2" />
                <p className="ml-2 text-base text-gray-500">Add tax invoice information</p>
            </button>}
            {!!taxInformation && <div className="w-full py-3 px-4 bg-white border border-gray-200 rounded-lg flex flex-col">
                <div className="flex flex-row w-full justify-between">
                    <h3 className="w-max font-semibold text-base text-gray-800">{taxInformation.fullName}</h3>
                    <button onClick={() => onEdit()}><img src={editIcon} alt="edit icon" className="" /></button>
                </div>
                <div className="font-normal text-base text-gray-800">{taxInformation.email}</div>
                <div className="font-normal text-fontSize-sm text-gray-500">{taxInformation.addressDetails} {taxInformation.postalCode} Tel.{taxInformation.phoneNumber}</div>
                <div className="font-normal text-fontSize-sm text-gray-500">Tax ID: {taxInformation.identification}</div>
            </div>}
        </>
    )
}

export default TaxInformationCard
