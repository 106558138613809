import Package from "../model/package"

interface Props {
    isSelect: boolean
    item: Package,
    onSelect: () => void
}

const PackageCard: React.FC<Props> = ({ isSelect = false, item, onSelect }) => {
    return (
        <div key={item.id} className={`px-4 py-3 ${isSelect ? 'bg-primary-50' : ' bg-white'} rounded-lg border ${isSelect ? 'border-primary' : 'border-gray-200'} cursor-pointer`} onClick={() =>
            onSelect()
        }>
            <div className="inline-flex items-center justify-center space-x-1 > * + *">
                <img src={item.token.icon} alt="icon" className="w-4 h-4 "></img>
                <div className="font-semibold text-base h-6">{item.no_of_coins.toLocaleString()}</div>
                {!!item.bonus && item.bonus > 0 && <p className="font-semibold text-base text-primary">+{item.bonus.toLocaleString()}</p>}
            </div>
            <div>
                <h4 className="ps-5 font-normal text-sm">{parseInt(item.price).toLocaleString()} {item.currency.toUpperCase()}</h4>
            </div>
        </div>
    )
}

export default PackageCard
