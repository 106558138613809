import { create } from "zustand";
import Package from "../model/package";
import DirectusClient from "../services/directus";
import PaymentMethod from "../model/paymentMethod";
import TaxInformation from "../model/taxInformation";
import PaymentClient from "../services/payment";

interface PackageState {
    packages: Package[];
    selectedPackage: Package | null;
    setSelectedPackage: (item: Package | null) => void;
    paymentMethods: PaymentMethod[];
    selectedPayment: PaymentMethod | null;
    setSelectedPayment: (payment: PaymentMethod) => void;
    taxInformation: TaxInformation | null;
    loading: boolean;
    getPackages: () => Promise<void>;
    getPaymentMethods: () => Promise<void>;
    getTaxInformation: () => Promise<void>;
    checkPackage: (pack: Package) => Promise<boolean>;
    pay: (email: string, customerName: string) => Promise<string | null>;
    updateTaxInformation: (fullName: string, email: string, phoneNumber: string, identificationType: string, identification: string, addressDetails: string, postalCode: string) => Promise<boolean>;
    payLoading: boolean;
    taxLoading: boolean;
}

const client = new DirectusClient()
const msClient = new PaymentClient()

const usePackagesStore = create<PackageState>((set, get) => ({
    packages: [],
    selectedPackage: null,
    paymentMethods: [],
    selectedPayment: null,
    taxInformation: null,
    loading: true,
    payLoading: false,
    taxLoading: false,
    getPackages: async () => {
        try {
            const packages = await client.getPackages()
            set({ packages, loading: false })
        } catch (e) {
            set({ loading: false })
        }
    },

    getPaymentMethods: async () => {
        try {
            const paymentMethods = await client.getPaymentMethods()
            set({ paymentMethods })
        } catch (e) { }
    },

    getTaxInformation: async () => {
        try {
            const taxInformation = await msClient.getTaxInformation()
            set({ taxInformation })
        } catch (e) { }
    },

    //Create payment
    pay: async (email, customerName) => {
        try {
            set({ payLoading: true })
            const isCheck = await get().checkPackage(get().selectedPackage!)
            if (!isCheck) {
                set({ payLoading: false })
                return ''
            }
            const txId = await msClient.pay(get().selectedPackage?.id!, get().selectedPayment?.slug!, email, customerName)
            set({ payLoading: false })
            return txId
        } catch (e) {
            set({ payLoading: false })
            return null
        }

    },

    // Set select package
    setSelectedPackage: (item: Package | null) => {
        if (!!item) {
            let updateList = get().paymentMethods
            for (let i = 0; i < updateList.length; i++) {
                const bonus = item.payment_bonus != null ? item.payment_bonus.find((element) => {
                    return element.payments_id === updateList[i].id;
                }) : null
                updateList[i].bonus = !!bonus ? bonus.amount : 0
                updateList[i].tokenIcon = item.token.icon
            }
            set(() => ({ paymentMethods: updateList }))
        }
        set(() => ({ selectedPackage: item, selectedPayment: null }))
    },

    checkPackage: async (pack: Package) => {
        try {
            const newPackage = await client.checkPackage(pack.id)
            if (!newPackage) return false
            const payment = get().selectedPayment
            const filterPaymentBonus = newPackage!.payment_bonus?.find((e) => e.payments_id === payment?.id)
            const isCheck = newPackage!.price === pack.price &&
                newPackage!.no_of_coins === pack.no_of_coins &&
                newPackage!.bonus === pack.bonus &&
                (!!filterPaymentBonus ? filterPaymentBonus?.amount === payment?.bonus : true);
            return isCheck
        } catch (e) {
            return false
        }
    },

    // Set select payment
    setSelectedPayment: (payment: PaymentMethod) => set(() => ({ selectedPayment: payment })),

    //Create tax information
    updateTaxInformation: async (fullName, email, phoneNumber, identificationType, identification, addressDetails, postalCode) => {
        try {
            set({ taxLoading: true })
            let success = false
            if (!!get().taxInformation?.id) {
                success = await msClient.updateTaxInformation({ id: get().taxInformation!.id, fullName, email, phoneNumber, identificationType, identification, addressDetails, postalCode })
            } else {
                success = await msClient.createTaxInformation({ fullName, email, phoneNumber, identificationType, identification, addressDetails, postalCode })
            }
            set({ taxLoading: false })
            return success
        } catch (e) {
            set({ taxLoading: false })
            return false
        }
    },

}));

export default usePackagesStore