// import { useEffect, useRef } from "react";
// import { Helmet } from "react-helmet";
// import { useSearchParams } from "react-router-dom";

import axios from "axios";
import { useEffect, useState } from "react";
interface PaymentPageProps { }

const PaymentPage: React.FC<PaymentPageProps> = () => {

    useEffect(() => {
        if (window && document) {
            const script = document.createElement('script')
            const head = document.getElementsByTagName('head')[0]
            script.src = 'https://cdn.omise.co/omise.js'
            script.type = 'text/javascript'
            head.appendChild(script)
            script.addEventListener('load', () => {
                //@ts-ignore
                OmiseCard.configure({
                    publicKey: process.env.REACT_APP_OPN_PUBLIC_KEY,
                    image: `${process.env.REACT_APP_SITE}/logo192.png`,
                    frameLabel: "bondbond",
                    style: {
                        alwaysFullScreen: true,
                        submitButton: { backgroundColor: "#1E1E1E" },
                        link: { textColor: '#1E1E1E' },
                        closeButton: { visible: false }
                    },
                });

                const urlParams = new URLSearchParams(window.location.search);
                const amount = +(urlParams.get('amount') || 0)
                const paymentMethod = urlParams.get('paymentMethod')
                let otherPaymentMethods: string[] = []
                if (paymentMethod === 'mobile_banking') {
                    otherPaymentMethods = [
                        "mobile_banking_kbank",
                        "mobile_banking_scb",
                        "mobile_banking_bay",
                        "mobile_banking_bbl",
                        "mobile_banking_ktb",
                    ]
                }
                //@ts-ignore
                OmiseCard.open({
                    amount: amount,
                    currency: "THB",
                    defaultPaymentMethod: paymentMethod,
                    otherPaymentMethods,
                    onCreateTokenSuccess: async (source: string, d: any) => {
                        const txId = (urlParams.get('txId') || '')
                        const idToken = urlParams.get('idToken')
                        try {
                            const resp = await axios.post(`${process.env.REACT_APP_PAYMENT_SERVICE_BASE_URL}/charge`, {
                                source: source,
                                txId,
                            }, {
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Accept-Language': "EN",
                                    'Authorization': `Bearer ${idToken}`
                                }
                            })
                            const { data } = resp.data

                            window.location.replace(data.details.metadata.authorizeUri)
                        } catch (e) {
                            // HANDLE API ERROR
                        }
                    }
                });
            })
        }
    }, [])

    return <div style={{ width: 400 }}></div>
}

export default PaymentPage;