import { User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import useAuthStore, { AuthState } from '../store/authStore'
import eye from '../assets/eye.png'
import eyeOff from '../assets/eyeOff.png'
import googleBtn from '../assets/google-btn.svg'
import facebookBtn from '../assets/facebook-btn.svg'
import appleBtn from '../assets/apple-btn.svg'
import lockIcon from '../assets/lock.svg'
import emailIcon from '../assets/email.svg'
import Logo from '../component/Logo'
import Button from '../component/Button'
import { isValidEmail } from '../utils/regex'
import TextInput from '../component/TextInput'
import DividerWithText from '../component/DividerWithText'
import ToastCustom from '../component/ToastCustom'

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [emailErrorText, setEmailErrorText] = useState<string | null>(null)
  const [passwordErrorText, setPasswordErrorText] = useState<string | null>(null)

  const { search } = useLocation()
  const navigate = useNavigate()
  const user = useAuthStore((state: AuthState) => state.user)
  const loading = useAuthStore((state: AuthState) => state.signInLoading)
  const signInWithPassword = useAuthStore((state: AuthState) => state.signInWithPassword)
  const signInWithGoogle = useAuthStore((state: AuthState) => state.signInWithGoogle)
  const signInWithFacebook = useAuthStore((state: AuthState) => state.signInWithFacebook)
  const signInWithApple = useAuthStore((state: AuthState) => state.signInWithApple)
  const signInWithToken = useAuthStore((state: AuthState) => state.signInWithToken)

  useEffect(() => {
    const autoSignIn = async (token: string) => {
      try {
        const authUser = await signInWithToken(token)
        redirectToPackages(authUser)
      } catch (err: any) {}
    }

    if (search) {
      const params = new URLSearchParams(search)
      const token = params.get('token')
      if (token) {
        autoSignIn(token)
      }
    }
  }, [search])

  useEffect(() => {
    if (user) {
      console.log('effect redirect', user)
      navigate('/packages')
    }
  }, [navigate, user])

  const redirectToPackages = (user: User | null) => {
    if (user !== null) {
      console.log('function redirect')
      navigate('/packages')
    }
  }

  const _signInWithPassword = async () => {
    try {
      await signInWithPassword(email, password)
    } catch (err: any) {
      handleSignInError(err)
    }
  }

  const _signInWithGoogle = async () => {
    try {
      await signInWithGoogle()
    } catch (err: any) {
      let customErr = typeof err === 'object' ? err.message : err
      handleSignInError(customErr, 'Google')
    }
  }

  const _signInWithFacebook = async () => {
    try {
      await signInWithFacebook()
    } catch (err: any) {
      let customErr = typeof err === 'object' ? err.message : err
      handleSignInError(customErr, 'Facebook')
    }
  }

  const _signInWithApple = async () => {
    try {
      await signInWithApple()
    } catch (err: any) {
      let customErr = typeof err === 'object' ? err.message : err
      handleSignInError(customErr, 'Apple')
    }
  }

  const handleSignInError = (code: string, channel?: string) => {
    switch (code) {
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        setPasswordErrorText('Incorrect email or password')
        break
      case 'auth/user-disabled':
        setEmailErrorText('Your account has been suspended')
        break
      case 'auth/popup-closed-by-user':
      case 'auth/user-cancelled':
      case 'auth/cancelled-popup-request':
        break // Do not toast if the user closed the popup
      case 'auth/operation-not-allowed':
        ToastCustom.error(
          <div>
            Couldn't sign up/login with {channel}
            <br />
            Please contact customer support.
          </div>,
        )
        break
      case 'email-is-null':
        ToastCustom.error(
          `Couldn't sign up/login with ${channel}. Please unhide the email on apple account setting or try another account.`,
        )
        break
      default:
        ToastCustom.error('Cannot login')
    }
  }

  const validateEmail = (setState: boolean = false) => {
    let err = null
    if (!email) {
      err = "Email can't be empty"
    } else if (!isValidEmail(email)) {
      err = 'Please enter a valid email'
    }
    if (setState) setEmailErrorText(err)
    return err
  }

  const validatePassword = (setState: boolean = false) => {
    let err = null
    if (!password) {
      err = "Password can't be empty"
    } else if (password.length < 8) {
      err = 'Password is too short'
    }
    if (setState) setPasswordErrorText(err)
    return err
  }

  const isFormValid = () => {
    return !validateEmail() && !validatePassword()
  }

  return (
    <div className="px-6 md:px-14 py-8 bg-white border border-gray-200 rounded-lg shadow flex flex-col items-center md:max-w-[600px] mx-auto mt-4 md:mt-8">
      <Logo className={'w-16 h-16'} />
      <h3 className="text-xl font-semibold mt-6 text-center">Welcome to bondbond</h3>
      <p className="mb-10 mt-0.5 text-gray-500 text-center">Let’s start to meet your artist</p>

      <TextInput
        required
        id="email"
        className="h-14"
        type="email"
        label="Email"
        value={email}
        leadingIcon={emailIcon}
        onChange={(e) => {
          setEmail(e.target.value)
        }}
        onBlur={() => validateEmail(true)}
        error={emailErrorText}
      />

      <TextInput
        required
        id="password"
        className="mt-8 h-14"
        type={showPassword ? 'text' : 'password'}
        label="Password"
        value={password}
        leadingIcon={lockIcon}
        trailingAction={
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="position absolute top-2 right-0"
          >
            {showPassword ? (
              <img style={{ height: 42 }} src={eye} alt="eye" />
            ) : (
              <img style={{ height: 42 }} src={eyeOff} alt="eyeOff" />
            )}
          </button>
        }
        onChange={(e) => {
          setPassword(e.target.value)
        }}
        onBlur={() => validatePassword(true)}
        error={passwordErrorText}
      />

      <Button
        type="submit"
        loading={loading}
        disabled={!isFormValid()}
        className="md:w-full my-8"
        onClick={() => _signInWithPassword()}
      >
        Login
      </Button>
      <DividerWithText text="OR" />

      <div className="flex my-8">
        <img
          className="h-auto hover:cursor-pointer"
          src={googleBtn}
          alt="google"
          onClick={() => _signInWithGoogle()}
        ></img>
        <img
          className="h-auto hover:cursor-pointer mx-8"
          src={facebookBtn}
          alt="facebook"
          onClick={() => _signInWithFacebook()}
        ></img>
        <img
          className="h-auto hover:cursor-pointer"
          src={appleBtn}
          alt="apple"
          onClick={() => _signInWithApple()}
        ></img>
      </div>

      <a
        href="/forgot-password"
        className="text-base font-semibold text-primary-700 mt-[18px] mb-[10px] hover:underline"
      >
        Forgot Password?
      </a>
    </div>
  )
}

export default Login
