import { create } from "zustand"
import PaymentClient from "../services/payment"
import PaymentTransaction from "../model/paymentTransaction"

interface TransactionState {
  transaction: PaymentTransaction | null
  loading: boolean
  getPaymentTransaction: (txId: string) => Promise<PaymentTransaction | null>
  downloadQrCode: (txId: string) => Promise<Blob | null>,
  downloading: boolean
}

const msClient = new PaymentClient()

const usePaymentTransactionStore = create<TransactionState>((set) => ({
  transaction: null,
  loading: true,
  downloading: false,
  getPaymentTransaction: async (txId: string) => {
    let transaction: PaymentTransaction | null = null
    try {
      transaction = await msClient.getTransactionStatus(txId)
    } catch (error) {
      console.log(error)
    }
    set({ transaction, loading: false })
    return transaction
  },
  downloadQrCode: async (txId: string) => {
    try {
      set({ downloading: true })
      const qrCodeFile = await msClient.getDownloadQrcode(txId)
      set({ downloading: false })
      return qrCodeFile
    }
    catch (error) {
      console.log(error)
      set({ downloading: false })
      return null
    }
  }
}));

export default usePaymentTransactionStore