import { format } from "date-fns"

// Format timeLeft to MM:ss
export const formatTimeToMMSS = (milliseconds: number) => {
  const totalSeconds = Math.floor(milliseconds / 1000)
  const minutes = Math.floor(totalSeconds / 60)
  const seconds = totalSeconds % 60
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
};

export const getNowTimestamp = () => {
  return format(new Date(), 'ddMMyyyy_HHmmss')
}