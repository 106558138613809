import { ReactNode, useEffect, useState } from 'react'

const FacebookProvider = ({ children }: { children: any }) => {
 const [isSdkLoaded, setIsSdkLoaded] = useState(false)
 
  useEffect(() => {
    const loadFbSdk = () => {
      return new Promise((resolve) => {
        if (window.FB) {
          resolve(window.FB)
        } else {
          window.fbAsyncInit = () => {
            window.FB.init({
              appId:  process.env.REACT_APP_FACEBOOK_APP_ID ?? '',
              xfbml: true,
              cookie: true,
              version: 'v21.0',
            })
            setIsSdkLoaded(true)
            resolve(window.FB)
          };

          // Load the SDK asynchronously
          const script = document.createElement('script')
          script.src = 'https://connect.facebook.net/en_US/sdk.js'
          script.async = true
          script.defer = true
          document.body.appendChild(script)
        }
      })
    }

    loadFbSdk().then(() => {
     console.log('Facebook SDK loaded')
   })
  }, [])

  if (!isSdkLoaded) {
   return null
 }

  return children
}

export default FacebookProvider
export {}
