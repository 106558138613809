import { create } from "zustand";
import DirectusClient from "../services/directus";
import UserConsent from "../model/userConsent";
import Policy, { PolicyType } from "../model/policy";

interface PendingConsentsState {
  hasPendingConsents: boolean;
  loading: boolean;
  getPendingConsents: () => Promise<void>
}

const client = new DirectusClient()

function isConsentValid(consent: UserConsent | null, policies: (Policy | null)[]): boolean {
  if (!consent) return false;

  return !policies.some(policy => {
    if (!policy) return false

    const { type, id } = policy
    switch (type) {
      case PolicyType.TermsAndConditions:
        return id !== consent.acceptTerm
      case PolicyType.PrivacyPolicy:
        return id !== consent.acceptPrivacyPolicy
      case PolicyType.PaidPolicy:
        return id !== consent.acceptPaidPolicy
      default:
        return false
    }
  });
}

const usePendingConsentsStore = create<PendingConsentsState>((set) => ({
  hasPendingConsents: true,
  loading: true,
  getPendingConsents: async () => {
    let isValid: boolean = false
    try {
      const policies = await Promise.all([
        client.getTermsAndConditions(),
        client.getPrivacyPolicy(),
        client.getPaidPolicy(),
      ])

      const consent = await client.getUserConsent()
      isValid = isConsentValid(consent, policies);
    } catch (error) {
      console.log(error)
    }

    set({
      hasPendingConsents: !isValid,
      loading: false,
    })
  }
}));

export default usePendingConsentsStore