/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import useAuthStore from '../store/authStore'
import usePendingConsentsStore from '../store/pendingConsentsStore'
import useProfileStore from '../store/profileStore'
import { BONDBOND_APP_STORE_URL, BONDBOND_PLAY_STORE_URL } from '../constant/store'

const PrerequisiteModal = () => {
  const authLoading = useAuthStore((state) => state.loading)
  const logout = useAuthStore((state) => state.logout)
  const getPendingConsents = usePendingConsentsStore((state) => state.getPendingConsents)
  const hasPendingConsents = usePendingConsentsStore((state) => state.hasPendingConsents)
  const consentLoading = usePendingConsentsStore((state) => state.loading)
  const profile = useProfileStore((state) => state.profile)
  const getProfile = useProfileStore((state) => state.getProfile)
  const profileLoading = useProfileStore((state) => state.loading)
  const user = useAuthStore((state) => state.user)

  const isVerified =
    user?.emailVerified || user?.providerData.findIndex((info) => info.providerId === 'facebook.com') !== -1
  const isLinkPhoneNumber = !!user?.phoneNumber
  const isAdmin = profile?.bandId != null
  const prerequisite = isVerified && isLinkPhoneNumber && !!profile && !hasPendingConsents && !isAdmin
  const allLoaded = !profileLoading && !authLoading && !consentLoading

  useEffect(() => {
    const fetchData = async () => {
      await getProfile()
      if (user) {
        await getPendingConsents()
      }
    }

    fetchData()
  }, [])

  if (!allLoaded || (allLoaded && prerequisite)) {
    console.log('!allLoaded || (allLoaded && prerequisite) ->', !allLoaded || (allLoaded && prerequisite))
    console.log('🔥 - ', { allLoaded, prerequisite })
    return null
  }

  const renderPrerequisiteList = () => {
    if (!allLoaded) return null
    return (
      <>
        {!isVerified && <li>Verify your email</li>}
        {!profile && <li>Set your username</li>}
        {hasPendingConsents && <li>Accept terms of service and privacy policy</li>}
        {!isLinkPhoneNumber && <li>Open bondbond wallet</li>}
        {isAdmin && <li>Your account, with Role Admin, cannot top up. Please try again.</li>}
      </>
    )
  }

  const openApp = () => {
    const deepLink = `${process.env.REACT_APP_BONDBOND_APP_DEEPLINK_URL}`

    // Check platform
    const userAgent = navigator.userAgent.toLowerCase()
    const isMac = /macintosh|mac os x/.test(userAgent)
    const isMobile = /android|iphone|ipad|ipod/.test(userAgent)

    if (isMobile) {
      // Open the deep link for mobile
      if (isVerified && !!profile && !hasPendingConsents && !isLinkPhoneNumber) {
        window.location.assign(`${deepLink}/phone-no-verification`)
      } else {
        window.location.assign(deepLink)
      }
    } else if (isMac) {
      // Open App Store URL for macOS
      window.location.assign(BONDBOND_APP_STORE_URL)
    } else {
      // Open Play Store URL for other platforms
      window.location.assign(BONDBOND_PLAY_STORE_URL)
    }
  }

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50" />
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="bg-white p-5 rounded-lg shadow-lg max-w-sm mx-10">
          <div className="flex flex-col">
            <h2 className="text-base font-bold mb-1">Your account isn't complete</h2>
            <p className="text-sm text-gray-500 mb-1">
              Please complete this list on the bondbond application before topping up coins on the website
            </p>
            {!prerequisite && (
              <ul className="list-disc font-bold mb-6 marker:text-xs text-sm" style={{ marginLeft: '26px' }}>
                {renderPrerequisiteList()}
              </ul>
            )}
            <div className="flex w-full space-x-2">
              <button
                onClick={logout}
                className="w-full rounded py-2.5 px-3.5 border shadow-xs text-gray-700 font-bold"
              >
                Cancel
              </button>
              <button
                onClick={openApp}
                className="w-full rounded py-2.5 px-3.5 bg-nightBlack shadow-xs text-white font-bold"
              >
                Open app
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrerequisiteModal
