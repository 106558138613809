import { create } from 'zustand'
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  signInWithCredential,
  signOut,
  onAuthStateChanged,
  User,
  signInWithCustomToken,
  OAuthCredential,
} from 'firebase/auth'
import { auth } from "../lib/firebase-config"
import AuthenticationClient from '../services/authentication';

export interface AuthState {
  user: User | null;
  loading: boolean; // Loading for sending password reset email
  authLoading: boolean; // Loading for auth
  signInLoading: boolean; // Loading for signIn
  initializeAuth: () => () => void; // Returns the unsubscribe function
  logout: () => Promise<void>;
  signInWithPassword: (email: string, password: string) => Promise<User | null>;
  signInWithGoogle: () => Promise<User | null>;
  signInWithFacebook: () => Promise<User | null>;
  signInWithApple: () => Promise<User | null>;
  signInWithToken: (token: string) => Promise<User | null>;
  sendPasswordResetEmail: (email: string) => Promise<void>;
}

// const fbLogin = (): OAuthCredential => {
//   return new Promise((resolve, reject) => {
//     window.FB.login((response) => {
//       if (response.status === 'connected') {
//         console.log('FB log in:', response)
//         const credential = FacebookAuthProvider.credential(response.authResponse.accessToken)
//         resolve(credential)
//       } else {
//         console.error('FB log in error:', response)
//         reject(new Error('FB log in error'))
//       }
//     }, { scope: 'email' })
//   });
// }

const client = new AuthenticationClient()


const useAuthStore = create<AuthState>((set) => ({
  user: null,
  loading: false,
  authLoading: true,
  signInLoading: false,

  // Initialize Firebase auth state listener
  initializeAuth: () => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user !== null && typeof user?.email !== 'string') return
      set({ user, authLoading: false })
    });
    return unsubscribe;
  },

  // Logout method
  logout: async () => {
    try {
      await signOut(auth)
      set({ user: null })
    } catch (err) {
    }
  },

  // Sign in with Google
  signInWithPassword: async (email: string, password: string) => {
    set({ signInLoading: true })
    try {
      const result = await signInWithEmailAndPassword(auth, email, password)
      set({ user: result.user, signInLoading: false })
      return result.user
    } catch (error: any) {
      const errorCode = error.code
      const errorMessage = error.message
      console.error(`Cannot sign in: (${errorCode}) ${errorMessage}`)
      set({ user: null, signInLoading: false })
      throw (errorCode)
    }
  },

  // Sign in with Google
  signInWithGoogle: async () => {
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({
      prompt: 'select_account'
    })
    set({ signInLoading: true })
    try {
      const result = await signInWithPopup(auth, provider)
      if (result.user.email === null || result.user.isAnonymous) {
        result.user.delete()
        throw new Error('email-is-null')
      } else {
        set({ user: result.user, signInLoading: false })
        return result.user
      }
    } catch (error: any) {
      const errorCode = error.code
      const errorMessage = error.message
      console.error(`Cannot sign in: (${errorCode}) ${errorMessage}`)
      set({ user: null, signInLoading: false })
      throw (errorCode || errorMessage)
    }
  },

 

  // Sign in with Facebook
  signInWithFacebook: async () => {
    if (!window.FB || typeof window.FB === 'undefined') {
      console.error('Facebook SDK is not loaded.')
      throw new Error('Facebook SDK is not loaded.')
    }

    let credential: OAuthCredential
    set({ signInLoading: true })
    try {
      credential = await new Promise<OAuthCredential>((resolve, reject) => {
        window.FB.login((response) => {
          if (response.status === 'connected') {
            const credential = FacebookAuthProvider.credential(response.authResponse.accessToken)
            resolve(credential)
          } else {
            console.error('FB log in error:', response)
            const errorMsg = response.status === 'unknown' ? 'auth/popup-closed-by-user' : response.status
            reject(new Error(errorMsg))
          }
        }, { scope: 'email' })
      })

      const result = await signInWithCredential(auth, credential)
      set({ user: result.user, signInLoading: false })
      return result.user
    } catch (error: any) {
      const errorCode = error.code
      const errorMessage = error.message
      console.error(`Cannot sign in: (${errorCode}) ${errorMessage}`)
      set({ user: null, signInLoading: false })
      throw (errorCode || errorMessage)
    }
  },

  // Sign in with Apple ID
  signInWithApple: async () => {
    const provider = new OAuthProvider('apple.com')
    provider.addScope('email')
    provider.addScope('name')
    set({ signInLoading: true })
    try {
      const result = await signInWithPopup(auth, provider)
      // In case we want to get token from provider
      // const credential = OAuthProvider.credentialFromResult(result);
      // const token = credential?.accessToken
      // const idToken = credential?.idToken
      if (result.user.email === null || result.user.isAnonymous) {
        result.user.delete()
        throw new Error('email-is-null')
      } else {
        set({ user: result.user, signInLoading: false })
        return result.user
      }
    } catch (error: any) {
      const errorCode = error.code
      const errorMessage = error.message
      console.error(`Cannot sign in: (${errorCode}) ${errorMessage}`)
      set({ user: null, signInLoading: false })
      throw (errorCode || errorMessage)
    }
  },

  sendPasswordResetEmail: async (email: string) => {
    set({ loading: true })
    try {
      await client.sendPasswordResetEmail(email)
      set({ loading: false })
    } catch (error: any) {
      set({ loading: false })
      throw (error.code)
    }
  },

  signInWithToken: async (token: string) => {
    const customToken = await client.createCustomToken(token)
    const result = await signInWithCustomToken(auth, customToken)
    set({ user: result.user, authLoading: false })
    return result.user
  },

}));

export default useAuthStore