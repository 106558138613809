import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import useAuthStore from '../store/authStore'
import Loading from './Loading'
import Logo from './Logo'
import AccountDropDown from './AccountDropDown'
import usePackagesStore from '../store/packageStore'
import PrerequisiteModal from './PrerequisiteModal'

import 'react-toastify/dist/ReactToastify.css'

const Layout = ({ isPublic = true }: { isPublic?: boolean }) => {
    const initializeAuth = useAuthStore((state) => state.initializeAuth)
    const authLoading = useAuthStore((state) => state.authLoading)
    const selectedPackage = usePackagesStore((state) => state.selectedPackage)
    const selectedPayment = usePackagesStore((state) => state.selectedPayment)
    const [isPayment, setIsPayment] = useState(false)
    const user = useAuthStore((state) => state.user)
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        const unsubscribe = initializeAuth()
        return () => unsubscribe()
    }, [initializeAuth])

    useEffect(() => {
        if (!authLoading) {
            if (!isPublic && user === null) {
                return navigate('/login')
            }
            if (location.pathname === '/') {
                return navigate('/packages')
            }
        }
    }, [authLoading, user])

    useEffect(() => {
        const isPayment = location.pathname === '/packages' && !!selectedPackage && !!selectedPayment
        setIsPayment(isPayment)
    }, [selectedPackage, selectedPayment, location.pathname])

    if (authLoading) {
        return <Loading />
    }

    return (
        <div className="w-full min-h-screen bg-layout-bg bg-cover flex flex-col bg-gray-25">
            {!!user && <PrerequisiteModal />}
            <ToastContainer position="top-right" theme="colored" autoClose={3000} hideProgressBar pauseOnHover />
            <header className="fixed w-full bg-white !z-10">
                <div className="w-full py-6 px-5 md:px-20 flex items-center justify-between">
                    <div className="flex items-center">
                        <div className="flex gap-x-3 items-center pr-2 md:pr-4">
                            <Logo className="w-8 h-8" /> <span className="text-primary font-semibold hidden md:block">bondbond</span>
                        </div>
                        <p className="border-l text-base px-2 md:pl-4 truncate max-[374px]:max-w-32">Official top-up website</p>
                    </div>
                    <div>{user && <AccountDropDown />}</div>
                </div>
            </header>
            <div className="flex-1 px-5 md:px-20 pt-[84px] pb-6">
                <Outlet />
            </div>
            <footer className={`bg-transparent bottom-0 w-full px-5 md:px-20 ${isPayment ? 'mb-[84px]' : ''}`}>
                <div className="w-full py-4 md:py-7 px-3 md:px-8 flex flex-col-reverse lg:flex-row items-start lg:items-center justify-between border-t border-gray-300">
                    <span className="text-gray-500 dark:text-gray-400 text-base mt-2 lg:mt-0">
                        ©
                        <a href="https://www.bondbond.live/" className="hover:underline">
                            bondbond
                        </a>
                        . All rights reserved.
                    </span>
                    <ul className="flex flex-wrap items-center text-gray-500 text-base">
                        <li>
                            <a href="https://www.bondbond.live/faqs" className="hover:underline pr-3 border-r">
                                FAQs
                            </a>
                        </li>
                        <li>
                            <a href="https://www.bondbond.live/terms" className="hover:underline mr-3 px-3 border-r">
                                Terms of service
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.bondbond.live/privacy-policy"
                                className="hover:underline mr-3 pr-3 max-[375px]:pl-0 border-r"
                            >
                                Privacy policy
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://www.bondbond.live/paid-policy"
                                className="hover:underline"
                            >
                                Paid policy
                            </a>
                        </li>
                    </ul>
                </div>
            </footer>
        </div>
    )
}

export default Layout
