import Button from "../component/Button"
import Logo from "../component/Logo"


const SuccessProcess = ({ mode }: { mode: 'resetPassword' | 'verifyEmail' }) => {
  return <div className="flex flex-col h-[90vh] lg:h-auto">
    <Logo className={'w-[65px] h-[65px] md:w-[105px] md:h-[105px]'} />
    <p className="font-medium text-[20px] mt-[20px]">Successfully {mode === 'resetPassword' ? 'reset password' : 'joined bondbond'}</p>
    <p className="mt-5 text-[14px]">{mode === 'resetPassword' ? 'Your password has been changed. You are now logged in.' : 'With bondbond Account, you can enjoy bondbond Community and much more!'}</p>
    <div className="mt-[auto] lg:mt-30 block lg:hidden">
      <a href={`${process.env.REACT_APP_BONDBOND_APP_DEEPLINK_URL || 'treeroots://bondbond.com'}/verifyemail/email/verifysuccess`}>
        <Button>Open bondbond</Button>
      </a>
    </div>
  </div>
}

export default SuccessProcess
