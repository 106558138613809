interface Props {
 className?: string
 text: string
}

const DividerWithText: React.FC<Props> = ({ className = '', text }) => {
 return (
  <div className={`relative flex items-center w-full ${className}`}>
   <div className='flex-grow border-t border-gray-300'></div>
   <span className='flex-shrink mx-2 text-gray-400'>{text}</span>
   <div className='flex-grow border-t border-gray-300'></div>
  </div>
 )
}

export default DividerWithText
