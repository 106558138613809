import PaymentMethod from "../model/paymentMethod"
import { isMobile } from "../utils/device";

interface Props {
    isSelect: boolean
    payment: PaymentMethod,
    onSelect: () => void
}

const PaymentMethodCard: React.FC<Props> = ({ isSelect = false, payment, onSelect }) => {
    const isActive = payment.status === 'Active'
    let isWebAvailable = isActive
    if (!isMobile) {
        isWebAvailable = !!payment.pay_channel?.find((e) => e === 'website')
    }
    const isAvailable = isActive && isWebAvailable
    return (
        <div key={payment.id} className={`h-16 px-4 py-3 flex flex-col justify-center ${!isAvailable ? 'bg-gray-100' : isSelect ? 'bg-primary-50' : ' bg-white'} rounded-lg border ${isSelect ? 'border-primary' : 'border-gray-200'} ${isAvailable ? 'cursor-pointer' : ''}`} onClick={() =>
            isAvailable ? onSelect() : {}
        }>
            <div className="inline-flex">
                <img className={`w-[24px] h-[24px] ${!isAvailable ? 'grayscale' : ''}`} alt="icon" src={`${process.env.REACT_APP_DIRECTUS_API_URL}/assets/${payment.logo}`} />
                <div className="ps-2">
                    <p className="font-semibold text-base text-ellipsis	line-clamp-1">{payment.name}</p>
                    {!!payment.bonus && isAvailable && <div className="flex flex-row items-center ">
                        <img src={payment.tokenIcon} alt="icon" className="w-4 h-4"></img>
                        <div className="ps-1">
                            <p className="font-semibold text-sm text-primary">+{payment.bonus.toLocaleString()}</p>
                        </div>
                    </div>}
                    {!isWebAvailable && isActive &&
                        <p className="font-semibold text-sm text-gray-500 text-ellipsis line-clamp-1">bondbond application only</p>
                    }
                    {!isActive &&
                        <p className="font-semibold text-sm text-gray-500 text-ellipsis line-clamp-1">Unavailable</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default PaymentMethodCard
