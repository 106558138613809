import { toast, ToastContent } from 'react-toastify'
import cancelIcon from '../assets/cancel.svg'
import checkIcon from '../assets/check_circle.svg'

class ToastCustom {
  static success(content: ToastContent<unknown>) {
    toast.success(content, {
      className: 'toast-custom-success',
      bodyClassName: 'toast-custom-success-body',
      icon:  ({theme, type}) =>  <img src={checkIcon} alt='success' />
    })
  }

  static error(content: ToastContent<unknown>) {
    toast.error(content, {
      className: 'toast-custom-error',
      bodyClassName: 'toast-custom-error-body',
      icon:  ({theme, type}) =>  <img src={cancelIcon} alt='error' />
    })
  }

  static info(content: ToastContent<unknown>) {
    toast.info(content, {
      className: 'toast-custom-info',
      bodyClassName: 'toast-custom-info-body',
    })
  }
}

export default ToastCustom
