import axios, { Axios } from "axios";
import { auth } from "../lib/firebase-config";
import Package from "../model/package";
import PaymentMethod from "../model/paymentMethod";
import WalletConfig from "../model/walletConfig";
import Customer from "../model/customer";
import Policy, { PolicyType } from "../model/policy";
import UserConsent from "../model/userConsent";
import { toCamelCase } from "../utils/object";

class DirectusClient {
    private readonly axiosInstance: Axios

    constructor() {
        this.axiosInstance = axios.create({
            baseURL: process.env.REACT_APP_DIRECTUS_API_URL,
        })

        this.axiosInstance.interceptors.request.use(async (config) => {
            const token = await auth.currentUser?.getIdToken()
            if (token) {
                config.headers['X-Authorization'] = `Bearer ${token}`
            }
            return config
        }, (error) => {
            // Handle the error
            return Promise.reject(error)
        })
    }

    async getPackages(): Promise<Package[]> {
        try {
            const resp = await this.axiosInstance.get<{ data: Package[] }>('/users/me/token_packages', {
                params: {
                    fields: '*,payment_bonus.*,token.*',
                    'filter[_and][0][status][_eq]': 'Active',
                    'filter[_and][0][_and][1][sales_channel][_eq]': 'website'
                }
            })
            const walletConfig = await axios.get<{ tokens: WalletConfig[] }>(`${process.env.REACT_APP_SITE}/config/wallet_config_v2.json`, {})
            let walletMap = new Map<string, string>()
            walletConfig.data.tokens.forEach((e) =>
                walletMap.set(e.token, e.icon)
            )
            const packageWithToken = resp.data.data.map((e) => {
                e.token.icon = walletMap.get(e.token.symbol) ?? ''
                return e
            })
            return packageWithToken

        } catch (error) {
            return Promise.reject(error)
        }
    }

    async checkPackage(id: number): Promise<Package | null> {
        try {
            const resp = await this.axiosInstance.get<{ data: Package[] }>('/users/me/token_packages', {
                params: {
                    fields: '*,payment_bonus.*,token.*',
                    'filter[_and][0][status][_eq]': 'Active',
                    'filter[_and][0][_and][1][id][_eq]': `${id}`,
                    'filter[_and][0][_and][1][sales_channel][_eq]': 'website',
                }
            })
            return resp.data.data.length > 0 ? resp.data.data[0] : null
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async getPaymentMethods(): Promise<PaymentMethod[]> {
        try {
            const resp = await this.axiosInstance.get<{ data: PaymentMethod[] }>('/users/me/payments', {
                params: {
                    fields: '*',
                    'filter[_and][0][status][_neq]': 'Draft',
                }
            })
            return resp.data.data
        } catch (error) {
            return Promise.reject(error)
        }
    }
    async getProfile(): Promise<Customer> {
        const resp = await this.axiosInstance.get<{ data: Customer }>('/users/me/customer')
        return toCamelCase(resp.data.data)
    }

    async getTermsAndConditions(): Promise<Policy | null> {
        const resp = await this.axiosInstance.get<{ data: Policy[] }>('/users/me/terms_and_conditions', {
            params: {
                limit: 1,
                sort: '-version',
            }
        })
        const result = toCamelCase(resp.data.data)
        if (!result.length) {
            return null
        }
        result[0].type = PolicyType.TermsAndConditions
        return result[0]
    }

    async getPrivacyPolicy(): Promise<Policy | null> {
        const resp = await this.axiosInstance.get<{ data: Policy[] }>('/users/me/Privacy_Policy', {
            params: {
                limit: 1,
                sort: '-version',
            }
        })
        const result = toCamelCase(resp.data.data)
        if (!result.length) {
            return null
        }
        result[0].type = PolicyType.PrivacyPolicy
        return result[0]
    }

    async getPaidPolicy(): Promise<Policy | null> {
        const resp = await this.axiosInstance.get<{ data: Policy[] }>('/users/me/paid_policy', {
            params: {
                limit: 1,
                sort: '-version',
            }
        })
        const result = toCamelCase(resp.data.data)
        if (!result.length) {
            return null
        }
        result[0].type = PolicyType.PaidPolicy
        return result[0]
    }

    async getUserConsent(): Promise<UserConsent | null> {
        const resp = await this.axiosInstance.get<{ data: UserConsent[] }>('/users/me/user_accept_terms')
        const result = toCamelCase(resp.data.data)
        if (!result.length) {
            return null
        }
        return result[0]
    }
}

export default DirectusClient