import Logo from "../component/Logo"


const FailProcess = ({ mode }: { mode: 'resetPassword' | 'verifyEmail' | 'default' }) => {
  // const renderTitle = () => {
  //   if (mode === 'resetPassword') {
  //     return 'reset password'
  //   }
  //   else if (mode === 'verifyEmail') {
  //     return 'verify'
  //   }
  //   return ''
  // }

  // const renderDescription = () => {
  //   if (mode === 'resetPassword') {
  //     return 'reset password'
  //   }
  //   else if (mode === 'verifyEmail') {
  //     return 'to verify'
  //   }
  //   return ''
  // }
  return <div>
    <Logo className={'w-[65px] h-[65px] md:w-[105px] md:h-[105px]'} />
    <p className="font-medium text-[20px] mt-[20px]">Unable to verify email</p>
    <p className="mt-5 text-[14px]">Your request to verify link has expired or already been used</p>
  </div>
}

export default FailProcess