export enum PolicyType {
  TermsAndConditions = "Terms and Conditions",
  PaidPolicy = "Paid Policy",
  PrivacyPolicy = "Privacy Policy",
}

interface Policy {
  id: string;
  sort?: number | null;
  userCreated: string;
  dateCreated: string; // ISO 8601 date string
  userUpdated: string;
  dateUpdated: string; // ISO 8601 date string
  version: number;
  type: PolicyType;
}

export default Policy