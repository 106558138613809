export const isValidEmail = (email: string) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
}

export const isValidThaiId = (id: string) => {
    const regex = /^[0-9]{13}$/
    return regex.test(id)
}

export const isValidPassportId = (id: string) => {
    const regex = /^.{8,}$/
    return regex.test(id)
}

export const isValidThaiPostal = (postal: string) => {
    const regex = /^[0-9]{5}$/
    return regex.test(postal)
}

export const isValidPostal = (postal: string) => {
    const regex = /^[0-9]{1,}$/
    return regex.test(postal)
}
