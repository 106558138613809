import { useEffect, useState } from "react"
import usePackagesStore from "../store/packageStore"
import Loading from "../component/Loading"
import useAuthStore from "../store/authStore"
import Spinner from "../component/Spinner"
import { useNavigate } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import { formatNumberForPayment } from "../utils/number"
import TaxInformationPages from "./taxInformationPages"
import Dialog from "../component/Dialog"
import TaxInformationCard from "../component/TaxInformationCard"
import PackageCard from "../component/PackageCard"
import PaymentMethodCard from "../component/PaymentMethodCard"
import useProfileStore from "../store/profileStore"
import ToastCustom from '../component/ToastCustom'

const TopUpPackages = () => {
    const getPackages = usePackagesStore((state) => state.getPackages)
    const getPaymentMethods = usePackagesStore((state) => state.getPaymentMethods)
    const getTaxInformation = usePackagesStore((state) => state.getTaxInformation)
    const taxInformation = usePackagesStore((state) => state.taxInformation)
    const packages = usePackagesStore((state) => state.packages)
    const paymentMethods = usePackagesStore((state) => state.paymentMethods)
    const packageLoading = usePackagesStore((state) => state.loading)
    const setSelectedPackage = usePackagesStore((state) => state.setSelectedPackage)
    const selectedPackage = usePackagesStore((state) => state.selectedPackage)
    const setSelectedPayment = usePackagesStore((state) => state.setSelectedPayment)
    const selectedPayment = usePackagesStore((state) => state.selectedPayment)
    const pay = usePackagesStore((state) => state.pay)
    const payLoading = usePackagesStore((state) => state.payLoading)
    const user = useAuthStore((state) => state.user)
    const profile = useProfileStore((state) => state.profile)
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const [openTaxInfo, setOpenTaxInfo] = useState(false)
    useEffect(() => {
        if (user) {
            getPackages()
            getPaymentMethods()
            getTaxInformation()
        }
    }, [user, getPackages, getPaymentMethods, getTaxInformation])

    useEffect(() => {
        if (!!selectedPackage || !!selectedPayment) {
            setSelectedPackage(null)
        }
    }, [packages, paymentMethods])

    if (packageLoading) {
        return <Loading />
    }
    const getTokenBonus = () => {
        const paymentBonus = selectedPayment?.bonus ?? 0
        const bonus = (selectedPackage?.bonus ?? 0) + paymentBonus
        return bonus
    }

    const createPayment = async () => {
        let email = taxInformation?.email
        if (!email) {
            email = user?.email ?? ''
        }
        const profileName = profile?.fullName ?? ''
        const txId = await pay(email, profileName)
        if (txId === null || txId === undefined) {
            ToastCustom.error('Payment failed. Please try again.')
            return
        }
        if (txId === '') {
            setShowModal(true)
            return
        }
        const idToken = await user?.getIdToken()
        const url =
            `/payment?txId=${txId}&amount=${formatNumberForPayment(selectedPackage!.price)}&paymentMethod=${selectedPayment!.slug}&idToken=${idToken}`
        if (idToken != null) {
            navigate(url)
        }
    }

    return <>
        <div className="lg:flex lg:gap-6 lg:pb-0 mt-4 md:mt-6">
            <div className="lg:w-1/4 p-4 bg-gradient-to-r from-primary to-primary-500 border border-gray-200 rounded-2xl shadow max-h-min">
                <h2 className="text-white font-bold text-xl">Top-up bonus coin</h2>
                <div className="pt-1 text-primary-100 font-normal text-fontSize-base">จ่ายน้อยกว่า ได้เหรียญเยอะกว่า กับ bondbond official top-up website</div>
            </div>
            <div className="mt-4 lg:mt-0 lg:w-3/4 p-4 bg-white border border-gray-200 rounded-lg shadow">
                <div className="flex gap-2">
                    <p className="font-semibold text-white bg-black rounded w-6 h-6 text-center">1</p>
                    <h3 className="font-semibold text-base pb-4">Choose packages</h3>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 auto-rows-auto gap-2 pb-10">
                    {packages.length > 0 && packages.map((item) => {
                        return (<PackageCard key={item.id} isSelect={selectedPackage === item} item={item} onSelect={() => { if (selectedPackage === item) return; setSelectedPackage(item) }} />)
                    })}
                </div>
                <div className="flex gap-2">
                    <p className="font-semibold text-white bg-black rounded w-6 h-6 text-center">2</p>
                    <h3 className="font-semibold text-base pb-4">Choose payment methods</h3>

                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 auto-rows-auto gap-2 pb-10">
                    {paymentMethods.length > 0 && paymentMethods.map((payment) => {
                        return (<PaymentMethodCard key={payment.id} isSelect={selectedPayment === payment} payment={payment} onSelect={() => setSelectedPayment(payment)} />)
                    })}
                </div>
                <div className="flex gap-2">
                    <p className="font-semibold text-white bg-black rounded w-6 h-6 text-center">3</p>
                    <h3 className="font-semibold text-base pb-4">Fill tax invoice information <span className="font-semibold text-base pb-4 text-gray-500">(Optional)</span></h3>

                </div>
                <TaxInformationCard taxInformation={taxInformation} onEdit={() => setOpenTaxInfo(true)} />
            </div>
        </div>

        {!!selectedPackage && !!selectedPayment && !payLoading &&
            <footer className="fixed bottom-0 left-0 z-20 w-full pt-4 pb-5 px-5 lg:px-20 bg-white border-t border-gray-200 rounded-t-lg shadow md:flex md:items-end md:justify-end ">
                <div className="flex flex-row justify-end items-center space-x-5 > * + *">
                    <div className="flex flex-col items-end">
                        <div className="flex flex-row items-center ">
                            <img src={selectedPackage.token.icon} alt="edit icon" className="w-4 h-4" />
                            <span className="ps-1 font-semibold text-fontSize-base text-gray-800 sm:text-center dark:text-gray-800">{selectedPackage.no_of_coins.toLocaleString()}
                            </span>
                            {getTokenBonus() > 0 &&
                                <span className="ps-1 font-semibold text-fontSize-base text-gray-800 sm:text-center dark:text-gray-800">+{getTokenBonus().toLocaleString()}
                                </span>
                            }
                        </div>
                        <div className="flex flex-row items-center ">
                            <span className="font-normal text-fontSize-base text-gray-800 sm:text-center dark:text-gray-800">Total:
                            </span>
                            <span className="ps-1 font-semibold text-fontSize-base text-primary sm:text-center dark:text-primary">{parseInt(selectedPackage.price).toLocaleString()} {selectedPackage.currency.toUpperCase()}
                            </span>
                        </div>
                    </div>
                    <button className='w-[112px] h-[44px] bg-black text-white rounded font-bold' onClick={() => createPayment()}>Pay</button>
                </div>
            </footer>
        }
        {payLoading && <div className="relative z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden  transition-all sm:my-8 sm:w-full sm:max-w-xs">
                        <div className="p-5 justify-items-center">
                            <div className="sm:flex sm:items-center">
                                <Spinner />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
        {<Dialog title="Package have been updated" subTitle="Please review the details again before proceeding the payment." textButton="OK" open={showModal} onClickButton={() => setShowModal(false)} />}
        {openTaxInfo && <TaxInformationPages information={taxInformation} open={openTaxInfo} onClose={() => setOpenTaxInfo(false)} onConfirm={() => { setOpenTaxInfo(false); getTaxInformation(); }} />}
    </>

}
export default TopUpPackages