const Dialog = ({
    title,
    subTitle,
    textButton,
    open,
    onClickButton,
}: {
    title: string,
    subTitle: string,
    textButton: string,
    open: boolean,
    onClickButton: () => void
}) => {

    return <>
        {open && <div className="relative z-30" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div className="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xs">
                        <div className="bg-white p-5 justify-items-center">
                            <div className="sm:flex sm:items-center">
                                <div className="text-center sm:mt-0 sm:text-center">
                                    <h3 className="text-fontSize-base font-semibold text-gray-800" id="modal-title">{title}</h3>
                                    <div className="mt-1">
                                        <p className="text-sm text-gray-500">{subTitle}</p>
                                    </div>
                                    <button className='w-full mt-6 bg-black py-[10px] text-white rounded font-bold' onClick={() => onClickButton()}>{textButton}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>}
    </>
}

export default Dialog