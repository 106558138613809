import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuthStore, { AuthState } from '../store/authStore'
import emailIcon from '../assets/email.svg'
import backIcon from '../assets/back.svg'
import communication from '../assets/communication.svg'
import Logo from '../component/Logo'
import Button from '../component/Button'
import { isValidEmail } from '../utils/regex'
import TextInput from '../component/TextInput'
import ToastCustom from '../component/ToastCustom'

const ForgotPassword = () => {
 const [isSubmitted, setIsSubmitted] = useState(false)
 const [email, setEmail] = useState('')
 const [emailErrorText, setEmailErrorText] = useState<string | null>(null)
 const navigate = useNavigate()
 const loading = useAuthStore((state: AuthState) => state.loading)
 const sendPasswordResetEmail = useAuthStore((state: AuthState) => state.sendPasswordResetEmail)
 const logout = useAuthStore((state: AuthState) => state.logout)

 const submit = async () => {
  try {
   await sendPasswordResetEmail(email)
   await logout()
   setIsSubmitted(true)
  } catch (err) {
   ToastCustom.error('Cannot send password reset email')
  }
 }

 const validateEmail = (setState: boolean = false) => {
  let err = null
  if (!email) {
   err = 'Email can\'t be empty'
  } else if (!isValidEmail(email)) {
   err = 'Please enter a valid email'
  }
  if (setState) setEmailErrorText(err)
  return err
 }

 const isFormValid = () => {
  return !validateEmail()
 }

 const formContent = () =>
  <>
   <a href="/login" className='flex self-start text-base text-primary mb-6 hover:underline'>
    <img src={backIcon} className='inline-block w-6 h-6' alt='back' />
    Back
   </a>
   <Logo className={'w-16 h-16'} />
   <h3 className='text-xl font-semibold mt-6 text-center'>Forgot password</h3>
   <p className='mb-10 mt-0.5 text-gray-500 text-center'>Have trouble logging in to your account? <br /> We got your back!</p>
   <TextInput
    required
    id='email'
    className='h-14'
    type="email"
    label='Email'
    value={email}
    leadingIcon={emailIcon}
    onChange={(e) => {
     setEmail(e.target.value)
    }}
    onBlur={() => validateEmail(true)}
    error={emailErrorText}
   />
   <Button type="submit" loading={loading} disabled={!isFormValid()} className='md:w-full mt-8' onClick={() => submit()}>Send Email</Button>
  </>

 const resultContent = () =>
  <>
   <img src={communication} className='w-[120px]' alt='communication' />
   <h3 className='text-xl font-semibold mt-3'>Please check your email</h3>
   <p className='mt-0.5 text-gray-500 text-center'>Reset password email has been sent to<br /> <span className='text-gray-800 font-bold'>{email}</span></p>
   <p className='mt-8 text-gray-500 text-center'>Don’t see email? check Junkmail box</p>
   <Button className='md:w-full mt-8' onClick={() => navigate('/login')}> Back to login page</Button>
  </>


 return (
  <div className='px-6 md:px-14 py-8 bg-white border border-gray-200 rounded-lg shadow flex flex-col items-center max-w-[600px] mx-auto mt-4 md:mt-8'>
   {isSubmitted ? resultContent() : formContent()}
  </div>
 )

}
export default ForgotPassword
