/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { BONDBOND_APP_STORE_URL, BONDBOND_PLAY_STORE_URL } from "../constant/store";
import { isAndroid, isIOS } from "../utils/device";

const ShareLink = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const uri = queryParams.get('URI')
  useEffect(() => {
    if (uri) {
      window.location.href = uri

      if (isAndroid) {
        setTimeout(() => {
          window.location.href = BONDBOND_PLAY_STORE_URL
        }, 2000)
      } else if (isIOS) {
        setTimeout(() => {
          window.location.href = BONDBOND_APP_STORE_URL
        }, 2000)
      } else {
        console.log('Not an iOS or Android device.')
      }
    }
  }, [])
  return <div></div>
}

export default ShareLink